import React from "react";
import styled from "styled-components";

const Meter = styled.div`
  position: relative;
  margin: 0 50px;
  width: 200px;
  height: 100px;
`;

const Gauge = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 40px;
    bottom: 0;
    width: 120px;
    height: 60px;
    background-color: #000000;
    border-radius: 90px 90px 0 0;
    filter: drop-shadow(2px 2px 7px #000000);
  }
`;

const Range = styled.div`
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 270deg at center bottom,
    green 0deg,
    lime 45deg,
    yellow 90deg,
    orange 130deg,
    red 160deg,
    purple 180deg
  );
`;

const Arrow = styled.div<{ $wokeLevel: number }>`
  display: block;
  position: absolute;
  left: 50%;
  bottom: -8px;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 110px solid #eeeeee;
  transform: rotate(${({ $wokeLevel }) => 180 + $wokeLevel * 18}deg);
  transform-origin: left center;
  filter: drop-shadow(2px 2px 7px #000000);
`;

const Cap = styled.div`
  background: #ffffff;
  display: block;
  position: absolute;
  left: 90px;
  bottom: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 2;
  filter: drop-shadow(2px 2px 7px #000000);
`;

type WokeMeterProps = {
  wokeLevel?: number;
};

const WokeMeter: React.FC<WokeMeterProps> = ({ wokeLevel }) => {
  if (wokeLevel == null) {
    return null;
  }

  return (
    <Meter>
      <Gauge>
        <Cap />
        <Arrow $wokeLevel={wokeLevel} />
        <Range />
      </Gauge>
    </Meter>
  );
};

export default WokeMeter;
