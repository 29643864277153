import {
  APICastMember,
  APIMovie,
  APIMovieFull,
  APIMoviesResponse,
  APIPerson,
  APISearchResult,
  APISearchResults,
  APITVShow,
  APIVideo,
  CastMember,
  MediaType,
  Movie,
  MovieFull,
  SearchResult,
  SearchResults,
  Video,
} from "./types";

export const toHoursAndMinutes = (totalMinutes: number) => ({
  hours: Math.floor(totalMinutes / 60),
  minutes: totalMinutes % 60,
});

export const castMemberFromApi = (
  apiCastMember: APICastMember
): CastMember => ({
  id: apiCastMember.id,
  castId: apiCastMember.cast_id,
  order: apiCastMember.order,
  name: apiCastMember.name,
  originalName: apiCastMember.original_name,
  gender:
    apiCastMember.gender === 0
      ? "Unknown"
      : apiCastMember.gender === 1
      ? "Female"
      : "Male",
  character: apiCastMember.character,
  popularity: apiCastMember.popularity,
  profilePath: apiCastMember.profile_path,
  adult: apiCastMember.adult,
});

export const videoFromApi = (apiVideo: APIVideo): Video => ({
  id: apiVideo.id,
  key: apiVideo.key,
  official: apiVideo.official,
  name: apiVideo.name,
  publishedDate: new Date(apiVideo.published_at),
  site: apiVideo.site,
  size: apiVideo.size,
  type: apiVideo.type,
});

export const movieFromApi = (apiMovie: APIMovie): Movie => ({
  id: apiMovie.id,
  title: apiMovie.title,
  tagline: apiMovie.tagline || "",
  overview: apiMovie.overview,
  language: apiMovie.original_language,
  releaseDate: apiMovie.release_date
    ? new Date(apiMovie.release_date)
    : undefined,
  popularity: apiMovie.popularity,
  isAdult: apiMovie.adult,
  budget: apiMovie.budget,
  revenue: apiMovie.revenue,
  runtime: apiMovie.runtime
    ? toHoursAndMinutes(apiMovie.runtime || 0)
    : undefined,
  hasVideo: apiMovie.video,
  paths: {
    backdrop: apiMovie.backdrop_path || "",
    poster: apiMovie.poster_path || "",
  },
  genres: apiMovie.genres,
  votes: {
    average: apiMovie.vote_average,
    count: apiMovie.vote_count,
  },
  wokeLevel: Math.round(Math.random() * 10),
});

export const tvShowFromApi = (apiTvShow: APITVShow) => ({
  id: apiTvShow.id,
  name: apiTvShow.name,
  firstAirDate: new Date(apiTvShow.first_air_date),
  genreIds: apiTvShow.genre_ids,
  overview: apiTvShow.overview,
  popularity: apiTvShow.popularity,
  paths: {
    backdrop: apiTvShow.backdrop_path || "",
    poster: apiTvShow.poster_path || "",
  },
  votes: {
    average: apiTvShow.vote_average,
    count: apiTvShow.vote_count,
  },
});

export const personFromApi = (apiPerson: APIPerson) => ({
  id: apiPerson.id,
  name: apiPerson.name,
  adult: apiPerson.adult,
  gender:
    apiPerson.gender === 0
      ? "Unknown"
      : apiPerson.gender === 1
      ? "Female"
      : "Male",
  knownForDepartment: apiPerson.known_for_department,
  popularity: apiPerson.popularity,
  profilePath: apiPerson.profile_path || "",
});

export const movieFullFromApi = (apiMovieFull: APIMovieFull): MovieFull => {
  const relevantReleaseDates =
    apiMovieFull.release_dates.results.find(
      (releaseDate) => releaseDate.iso_3166_1 === "US"
    )?.release_dates ?? [];
  const releaseDateInfo = relevantReleaseDates.pop();
  const releaseCertification = releaseDateInfo?.certification || "";

  return {
    ...movieFromApi(apiMovieFull),
    releaseCertification,
    cast: apiMovieFull.credits.cast.map(castMemberFromApi),
    videos: apiMovieFull.videos.results.map(videoFromApi),
  };
};

export const moviesFromApi = (
  apiMoviesResponse: APIMoviesResponse
): Movie[] => {
  return apiMoviesResponse.results.map(movieFromApi);
};

const searchResultFromAPI = (
  apiSearchResult: APISearchResult
): SearchResult => {
  switch (apiSearchResult.media_type) {
    case "movie":
      return {
        ...movieFromApi(apiSearchResult),
        mediaType: MediaType.MOVIE,
      };
    case "tv":
      return {
        ...tvShowFromApi(apiSearchResult),
        mediaType: MediaType.TV_SHOW,
      };
    case "person":
      return {
        ...personFromApi(apiSearchResult),
        mediaType: MediaType.PERSON,
      };
  }
};

export const searchResultsFromAPI = (
  apiSearchResults: APISearchResults
): SearchResults => {
  return apiSearchResults.results
    .filter((datum) => datum.media_type === "movie")
    .map(searchResultFromAPI);
};
