import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  height: 60px;
  line-height: 60px;

  & b {
    color: #f7b139;
    font-weight: inherit;
    font-size: 40px;
  }
`;

const LogoVerticalWrapper = styled.div`
  display: block;
  line-height: 37px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 150px;
  height: 150px;
  padding-top: 14px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(1, 1, 1, 0.6) 20%,
      rgba(0, 0, 0, 0.6)
    ),
    repeating-radial-gradient(
      ellipse at 100% 400%,
      #222,
      #ccc 0.5%,
      #555 0.4%,
      #999 1%
    );
  filter: drop-shadow(2px 2px 7px #000000);

  & b {
    color: #000000;
    font-weight: inherit;
    font-size: 40px;
  }
`;

const WokeBackground = styled.div`
  background: rgb(247 177 57 / 80%);
  padding: 4px 0px;
`;

const LogoText = styled.div`
  font-family: "Bangers", sans-serif;
  font-size: 35px;
  font-weight: 200;
  z-index: 1;
`;

function Logo() {
  return (
    <LogoWrapper>
      <LogoText>
        No <b>Woke</b> Zone
      </LogoText>
    </LogoWrapper>
  );
}

export function LogoVertical() {
  return (
    <LogoVerticalWrapper>
      <LogoText>
        <div>No</div>
        <WokeBackground>
          <b>Woke</b>
        </WokeBackground>
        <div>Zone</div>
      </LogoText>
    </LogoVerticalWrapper>
  );
}

export default Logo;
