import { atom } from "jotai";
import api from "../api";

export const movieIdAtom = atom("");
export const movieAtom = atom(async (get) => {
  const movieId = get(movieIdAtom);
  const movie = await api.movies.get(movieId);

  return movie;
});

export const popularMoviesAtom = atom(async () => {
  const popularMovies = await api.movies.getPopular();

  return popularMovies;
});
