export const getColorsForRating = (rating: number) => {
  if (rating >= 8.5) {
    return ['#048001', '#66fc05'];
  } else if (rating >= 7) {
    return ['#66fc05', '#c0bc21'];
  } else if (rating >= 5) {
    return ['#c0bc21', '#ffad00'];
  } else if (rating >= 4) {
    return ['#ffad00', '#f50019'];
  } else if (rating >= 1) {
    return ['#f50019', '#82017d'];
  } else {
    return ['#82017d', '#000000'];
  }
};

export const getOpinion = (wokeLevel: number | undefined, rating: number) => {
  if (!wokeLevel) {
    if (rating >= 8.5) {
      return {score: 10, description: 'Excellent movie'};
    } else if (rating >= 8) {
      return {score: 8, description: 'Great movie'};
    } else if (rating >= 7) {
      return {score: 6, description: 'Good movie'};
    } else if (rating >= 6) {
      return {score: 4, description: 'Okay movie'};
    } else {
      return {score: 2, description: 'Unwatchable'};
    }
  } else if (wokeLevel >= 8) {
    if (rating <= 6) {
      return {score: 0, description: 'Train wreck!'};
    } else if (rating <= 9) {
      return {score: 2, description: 'Unwatchably woke'};
    } else {
      return {score: 4, description: 'Uber woke'};
    }
  } else if (wokeLevel >= 7) {
    if (rating <= 6) {
      return {score: 2, description: 'Really woke, unwatchable'};
    } else if (rating <= 8.5) {
      return {score: 4, description: 'Too woke to watch'};
    } else {
      return {score: 5, description: 'Really woke'};
    }
  } else if (wokeLevel >= 5) {
    if (rating <= 6) {
      return {score: 3, description: 'Woke, unwatchable'};
    } else if (rating <= 8.5) {
      return {score: 6, description: 'Woke, watchable'};
    } else {
      return {score: 7, description: 'Woke, good'};
    }
  } else if (wokeLevel >= 3) {
    if (rating <= 6) {
      return {score: 4, description: 'Somewhat woke, unwatchable'};
    } else if (rating <= 8) {
      return {score: 6, description: 'Somewhat woke, passable'};
    } else {
      return {score: 7.5, description: 'Somewhat woke, worth watching'};
    }
  } else if (wokeLevel >= 1) {
    if (rating <= 5) {
      return {score: 4, description: 'Really bad'};
    } else if (rating <= 7) {
      return {score: 5, description: 'Not great'};
    } else if (rating < 8.5) {
      return {score: 7.5, description: 'Worth watching'};
    } else {
      return {score: 9, description: 'Watch it!'};
    }
  } else {
    if (rating <= 5) {
      return {score: 4, description: 'No Woke, unwatchable'};
    } else if (rating <= 7) {
      return {score: 6, description: 'No Woke, passable'};
    } else if (rating < 8) {
      return {score: 7.5, description: 'No Woke, good'};
    } else if (rating < 8.5) {
      return {score: 9, description: 'No Woke and great!'};
    } else {
      return {score: 10, description: 'No Woke and excellent!'};
    }
  }
}
