import { useAtom } from "jotai";
import React from "react";
import { useParams } from "react-router-dom";
import { movieIdAtom } from "../../state/movie";
import MoviePage from "./Movie";

const MoviePageRoute: React.FC = () => {
  const { movieId } = useParams();
  const [_, setMovieId] = useAtom(movieIdAtom);

  React.useEffect(() => {
    if (movieId) {
      setMovieId(movieId.split("-")[0]);
    }
  }, [movieId, setMovieId]);

  return (
    <React.Suspense fallback={<span>Loading...</span>}>
      {movieId ? <MoviePage /> : null}
    </React.Suspense>
  );
};

export default MoviePageRoute;
