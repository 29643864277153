import axios from 'axios';
import {MOVIE_DB_API_KEY, MOVIE_DB_API_URL} from './index';
import { searchResultsFromAPI } from './transforms';
import { APISearchResults } from './types';

const get = async (query: string) => {
  const {data} = await axios.get<APISearchResults>(`${MOVIE_DB_API_URL}/search/multi`, {
    params: {
      api_key: MOVIE_DB_API_KEY,
      query,
    }
  });
  return searchResultsFromAPI(data);
};

const api = {
  get,
};

export default api;
