import { Container } from "@mui/system";
import { useAtom } from "jotai";
import React from "react";
import styled from "styled-components";
import { popularMoviesAtom } from "../../state/movie";

const Section = styled.div`
  margin-top: 32px;
`;

const Title = styled.h1`
  font-family: "Bangers";
  font-size: 35px;
`;

const PosterContainer = styled.div`
  position: relative;

  &:after {
    content: "";
    display: block;
    background: linear-gradient(90deg, transparent, white);
    height: 100%;
    position: absolute;
    right: -4px;
    top: 0;
    bottom: 0;
    width: 100px;
  }
`;

const ScrollArea = styled.div`
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding: 4px;
  margin: 0 -4px;

  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
`;

const Poster = styled.img`
  max-width: 12vw;
  border-radius: 5px;
  filter: drop-shadow(1px 1px 3px #000000);
  margin-right: 16px;

  &:hover {
    position: relative;
    top: -2px;
    left: -1px;
    filter: drop-shadow(2px 2px 4px #000000);
  }

  &:active {
    position: relative;
    top: 0;
    left: 0;
    filter: drop-shadow(1px 1px 1px #000000);
  }
`;

const MoviesSectionPopular: React.FC = () => {
  const [popularMovies] = useAtom(popularMoviesAtom);

  return (
    <Section>
      <Container maxWidth="xl">
        <Title>Popular Movies</Title>
        <PosterContainer>
          <ScrollArea>
            {popularMovies.map((movie) => (
              <a href={`/movie/${movie.id}`}>
                <Poster
                  alt="Movie poster"
                  src={`https://image.tmdb.org/t/p/w500/${movie.paths.poster}`}
                />
              </a>
            ))}
          </ScrollArea>
        </PosterContainer>
      </Container>
    </Section>
  );
};

export default MoviesSectionPopular;
