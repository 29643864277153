import { Link } from "react-router-dom";
import { AppBar, Container, Grid, Toolbar } from "@mui/material";
import styled from "styled-components";
import Logo from "../components/Logo";
import SearchInput from "../components/SearchInput";

const StyledAppBar = styled(AppBar)`
  && {
    background-color: #000000;
    border-bottom: 1px solid #222;
  }
`;

function Header() {
  return (
    <StyledAppBar position="static">
      <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Link style={{ textDecoration: "none" }} to="/">
                <Logo />
              </Link>
            </Grid>
            <Grid item style={{ position: "relative" }}>
              <SearchInput />
            </Grid>
            <Grid item>Sign In</Grid>
          </Grid>
        </Container>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
