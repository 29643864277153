import React from "react";
import videojs from "video.js";
import "videojs-youtube";

// Styles
import "video.js/dist/video-js.css";

type VideoPlayerProps = {
  src: string;
  videoType: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, videoType }) => {
  const [player, setPlayer] = React.useState<videojs.Player>();

  const videoNodeRef = React.useRef(null);

  React.useEffect(() => {
    if (!videoNodeRef.current) {
      return;
    }

    const options = {
      autoplay: true,
      controls: true,
      sources: [{ src, type: videoType }],
      techOrder: ["youtube"],
      youtube: { fs: 0, controls: 0, ytControls: 0, autoplay: 0 },
    };
    const videoPlayer = videojs(videoNodeRef.current, options).ready(() =>
      console.log("onPlayerReady")
    );

    setPlayer(videoPlayer);

    return () => {
      player?.dispose();
    };
  }, []);

  return (
    <div className="c-player" style={{ width: "100%", height: "100%" }}>
      <div
        className="c-player__screen"
        data-vjs-player="true"
        style={{ width: "100%", height: "100%" }}
      >
        <video className="video-js" ref={videoNodeRef} />
      </div>
    </div>
  );
};

export default VideoPlayer;
