import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
} from "@mui/material";
import { CastMember } from "../../api/types";
import MovieHero from "./MovieHero";
import { movieAtom } from "../../state/movie";
import { useAtom } from "jotai";
import iconFeminism from "../../images/woke-icons/feminism-128.png";
import iconPolitical from "../../images/woke-icons/political-128.png";
import iconEnvironment from "../../images/woke-icons/environment-128.png";
import iconDiversity from "../../images/woke-icons/diversity-128.png";
import iconGlobalism from "../../images/woke-icons/globalism-128.png";
import iconEconomics from "../../images/woke-icons/economics-128.png";
import iconLgbt from "../../images/woke-icons/lgbt-128.png";
import { Masonry } from "@mui/lab";
import { getColorsForRating, getOpinion } from "../../utils/ratings";

const SectionHeader = styled.h3`
  font-family: "Bangers";
  font-size: 35px;
  text-align: center;
`;

const SectionSubhead = styled.h5<{ $ratingColors: string[] }>`
  font-size: 20px;
  max-width: 800px;
  border-radius: 10px;
  margin: 0 auto 24px;
  padding: 16px 24px;
  text-align: center;
  border-width: 5px;
  border-style: solid;
  border-image-source: linear-gradient(
    to right,
    ${({ $ratingColors }) => $ratingColors.join(", ")}
  );
  border-image-slice: 1;
`;

const WokeItemCard = styled(Card)`
  & p {
    margin: 0 0 8px;
    padding: 0;
  }
`;

const Section = styled(Container)`
  padding: 16px 0;
`;

const CastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
`;

const CastProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #000000;
  width: 168px;
  padding: 8px 0 16px;

  h3 {
    font-weight: bold;
  }

  h4 {
    font-weight: normal;
    white-space: normal;
    text-align: center;
  }

  h3,
  h4 {
    padding: 0 8px;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
`;

const CastProfileImageWrapper = styled.div`
  width: 152px;
  margin: 8px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  line-height: 228px;
  background: #d5d5d5;
}

  & img {
    width: 100%;
    display: block;
    height: auto;
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
    }
  }
`;

const MoviePage: React.FC = () => {
  const navigate = useNavigate();
  const [movie] = useAtom(movieAtom);

  React.useEffect(() => {
    const releaseDateString = movie.releaseDate
      ? ` (${movie.releaseDate.getFullYear()})`
      : "";
    const strippedTitle = movie.title
      .split(" ")
      .map((v) => v.replace(/\W/g, ""))
      .filter(Boolean)
      .join("-")
      .toLowerCase();

    document.title = `${movie.title}${releaseDateString} | No Woke Zone`;
    navigate(`/movie/${movie.id}-${strippedTitle}`, { replace: true });
  }, [movie, navigate]);

  const { score: wokeScore } = getOpinion(movie.wokeLevel, movie.votes.average);
  const wokeColors = getColorsForRating(wokeScore);

  return (
    <>
      <MovieHero movie={movie} />
      <Section style={{ background: "#ffffff" }} maxWidth="xl">
        <SectionHeader>Is {movie.title} woke?</SectionHeader>
        <SectionSubhead $ratingColors={wokeColors}>
          Sing 2 is a fun kids movie that has a couple very mild messages. It's
          a generally good movie worth seeing and kids love it.
        </SectionSubhead>

        <Masonry columns={3} spacing={2}>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconPolitical}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Political Wokism"
              subheader="Mild"
            />
            <CardContent>
              <p>
                The main antagonist is a thinly veiled parody of Donald Trump.
                With various 'Trumpisms' such as saying "huge" and "losers" it
                is made clear throughout the movie that the bad guy is basically
                Trump.
              </p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconFeminism}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Gender Wokism"
              subheader="Very Mild"
            />
            <CardContent>
              <p>
                Ash, one of the main female characters, is told she will only be
                paid half of what some other acts are paid. The character is
                very condescending to Ash and tells her he pays people what they
                are "worth". Ash responds by saying she doesn't let "guys" like
                him tell her what her worth is.
              </p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconEnvironment}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Environmental Wokism"
              subheader="Intense"
            />
            <CardContent>
              <p>
                This isn't really in there, just adding more to fill up space.
                This one is not as long as others.
              </p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconLgbt}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="LGBT Wokism"
              subheader="Moderate"
            />
            <CardContent>
              <p>
                This is something in the movie, with more details here. Another
                sentence that adds more details. Oh look, some more details
                here.
              </p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconEconomics}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Communism"
              subheader="Intense"
            />
            <CardContent>
              <p>
                Buster Moon is a communist. No, not really, just testing this.
                This isn't really in there, just adding more to fill up space.
              </p>
              <p>
                This one is not as long as others. This one is really long with
                paragraphs. This paragraph has a few sentences.
              </p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconDiversity}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Diversity Wokism"
              subheader="Mild"
            />
            <CardContent>
              <p>
                This one is not as long as others but close. This isn't really
                in there, just adding more to fill up space.
              </p>
              <p>Second paragraph without much in it.</p>
              <p>Third paragraph.</p>
            </CardContent>
          </WokeItemCard>
          <WokeItemCard sx={{ maxWidth: 500, margin: "0 0 16px 16px" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={iconGlobalism}
                  sx={{ width: 56, height: 56 }}
                  variant="square"
                />
              }
              title="Globalism"
              subheader="Extreme"
            />
            <CardContent>
              <p>
                This is something in the movie, with more details here. Another
                sentence that adds more details. Oh look, some more details
                here.
              </p>
            </CardContent>
          </WokeItemCard>
        </Masonry>
      </Section>
      <Section style={{ background: "#eeeeee" }} maxWidth="xl">
        <SectionHeader>Top Billed Cast</SectionHeader>
        <CastWrapper>
          {movie.cast.slice(0, 12).map((castMember: CastMember) => (
            <CastProfile key={castMember.id}>
              <CastProfileImageWrapper>
                {castMember.profilePath ? (
                  <img
                    alt={castMember.name}
                    src={`https://image.tmdb.org/t/p/w185/${castMember.profilePath}`}
                  />
                ) : (
                  <span>No profile image</span>
                )}
              </CastProfileImageWrapper>
              <h3>{castMember.name}</h3>
              <h4>{castMember.character}</h4>
            </CastProfile>
          ))}
        </CastWrapper>
      </Section>
    </>
  );
};

export default MoviePage;
