import { Container } from "@mui/system";
import { useAtom } from "jotai";
import React from "react";
import styled from "styled-components";
import { popularMoviesAtom } from "../../state/movie";

const Section = styled.div`
  margin-top: 32px;
  background: linear-gradient(90deg, #111, #000 50%, #fff 50%, #eee 100%);
  border-width: 5px 0 50px;
  border-style: solid;
  border-image-source: linear-gradient(
    90deg,
    green,
    lime 50%,
    orange 50%,
    purple 100%
  );
  border-image-slice: 1;
`;

const Title = styled.h1`
  font-family: "Bangers";
  font-size: 35px;
  margin-bottom: 8px;
`;

const Poster = styled.img`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 1;
  min-width: 0;

  border-radius: 5px;
  filter: drop-shadow(1px 1px 3px #000000);

  &:hover {
    position: relative;
    top: -2px;
    left: -1px;
    filter: drop-shadow(2px 2px 4px #000000);
  }

  &:active {
    position: relative;
    top: 0;
    left: 0;
    filter: drop-shadow(1px 1px 1px #000000);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 64px;
`;

const MovieSection = styled.div`
  flex: 1;
  padding: 16px 16px 24px;
`;

const MovieSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;

  & img {
    width: 100%;
    height: auto;
  }
`;

const MoviesSectionWokeAndNot: React.FC = () => {
  const [popularMovies] = useAtom(popularMoviesAtom);

  const wokeFreeMovies = [
    popularMovies[19],
    popularMovies[10],
    popularMovies[18],
  ];
  const wokeMovies = [popularMovies[12], popularMovies[15], popularMovies[2]];

  return (
    <Section>
      <Container maxWidth="xl">
        <Row>
          <MovieSection>
            <Title style={{ color: "#fff" }}>Good &amp; Woke Free</Title>
            <MovieSectionRow>
              {wokeFreeMovies.map((movie) => (
                <a href={`/movie/${movie.id}`}>
                  <Poster
                    alt="Movie poster"
                    src={`https://image.tmdb.org/t/p/w500/${movie.paths.poster}`}
                  />
                </a>
              ))}
            </MovieSectionRow>
          </MovieSection>

          <MovieSection>
            <Title>Recently Woke</Title>
            <MovieSectionRow>
              {wokeMovies.map((movie) => (
                <a href={`/movie/${movie.id}`}>
                  <Poster
                    alt="Movie poster"
                    src={`https://image.tmdb.org/t/p/w500/${movie.paths.poster}`}
                  />
                </a>
              ))}
            </MovieSectionRow>
          </MovieSection>
        </Row>
      </Container>
    </Section>
  );
};

export default MoviesSectionWokeAndNot;
