// Back-end values

export type APICastMember = {
  id: number;
  cast_id: number;
  order: number;
  name: string;
  original_name: string;
  gender: number;
  character: string;
  popularity: number;
  profile_path: string;
  adult: boolean;
};

export type APIVideo = {
  id: string;
  key: string;
  official: boolean;
  name: string;
  published_at: string;
  site: string;
  size: number;
  type: string;
  iso_639_1: string;
  iso_3166_1: string;
};

export type APIMovie = {
  id: number;
  adult: boolean;
  backdrop_path: string | null;
  budget: number;
  genres: [
    {
      id: number;
      name: string;
    }
  ];
  homepage: string | null;
  imdb_id: string | null;
  original_language: string;
  original_title: string;
  title: string;
  overview: string;
  popularity: number;
  poster_path: string | null;
  production_companies: [
    {
      id: number;
      name: string;
    }
  ];
  logo_path: string | null;
  origin_country: string;
  release_date: string;
  revenue: number;
  runtime: number | null;
  status:
    | "Rumored"
    | "Planned"
    | "In Production"
    | "Post Production"
    | "Released"
    | "Canceled";
  tagline: string | null;
  video: boolean;
  vote_average: number;
  vote_count: number;
};

export type APIMovieFull = APIMovie & {
  release_dates: {
    results: [
      {
        iso_3166_1: string;
        release_dates: [
          {
            certification: string;
            release_date: string;
          }
        ];
      }
    ];
  };
  credits: {
    cast: APICastMember[];
  };
  videos: {
    results: APIVideo[];
  };
};

export type APIMoviesResponse = {
  results: APIMovieFull[];
};

export type APITVShow = {
  id: number;
  name: string;
  first_air_date: string;
  genre_ids: number[];
  origin_country: string[];
  original_language: string;
  original_name: string;
  overview: string;
  popularity: number;
  poster_path: string;
  backdrop_path: string;
  vote_average: number;
  vote_count: number;
};

export type APIPerson = {
  id: number;
  name: string;
  adult: boolean;
  gender: number;
  known_for: [APITVShow | APIMovie];
  known_for_department: string;
  popularity: number;
  profile_path: string | null;
};

export type APISearchResult =
  | ({ media_type: "movie" } & APIMovie)
  | ({ media_type: "tv" } & APITVShow)
  | ({ media_type: "person" } & APIPerson);

export type APISearchResults = {
  results: APISearchResult[];
};

// Front-end values

export type CastMember = {
  id: number;
  castId: number;
  order: number;
  name: string;
  originalName: string;
  gender: string;
  character: string;
  popularity: number;
  profilePath: string;
  adult: boolean;
};

export type Video = {
  id: string;
  key: string;
  official: boolean;
  name: string;
  publishedDate: Date;
  site: string;
  size: number;
  type: string;
};

export type Movie = {
  id: number;
  title: string;
  tagline: string;
  overview: string;
  language: string;
  releaseDate?: Date;
  popularity: number;
  isAdult: boolean;
  budget: number;
  revenue: number;
  runtime?: { hours: number; minutes: number };
  hasVideo: boolean;
  paths: {
    backdrop: string;
    poster: string;
  };
  genres: [
    {
      id: number;
      name: string;
    }
  ];
  votes: {
    average: number;
    count: number;
  };
  wokeLevel: number;
};

export type MovieFull = Movie & {
  releaseCertification: string;
  cast: CastMember[];
  videos: Video[];
};

export type TVShow = {
  id: number;
  name: string;
  firstAirDate: Date;
  genreIds: number[];
  overview: string;
  popularity: number;
  paths: {
    backdrop: string;
    poster: string;
  };
  votes: {
    average: number;
    count: number;
  };
};

type MovieWithMediaType = { mediaType: MediaType.MOVIE } & Movie;
type TVShowWithMediaType = { mediaType: MediaType.TV_SHOW } & TVShow;
type PersonWithMediaType = { mediaType: MediaType.PERSON } & Person;

export type Person = {
  id: number;
  name: string;
  adult: boolean;
  gender: string;
  knownForDepartment: string;
  popularity: number;
  profilePath: string;
};

export enum MediaType {
  MOVIE = "movie",
  TV_SHOW = "tv",
  PERSON = "person",
}

export type SearchResult =
  | MovieWithMediaType
  | TVShowWithMediaType
  | PersonWithMediaType;

export type SearchResults = SearchResult[];
