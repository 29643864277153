import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import api from "../api";
import { MediaType, Movie, Person, SearchResult, TVShow } from "../api/types";
import { SearchRounded } from "@mui/icons-material";

const SearchField = styled(TextField)`
  & label,
  & label.Mui-focused,
  & .MuiInputAdornment-root {
    color: #ffffff;
  }
  & .MuiOutlinedInput-root {
    color: #ffffff;

    fieldset,
    &:hover fieldset {
      border-color: #ffffff;
    }

    &.Mui-focused fieldset {
      border-color: #f7b139;
    }
  }
`;

const SearchResults = styled.div`
  position: absolute;
  top: 36px;
  left: 0;
  width: 500px;
  background: #000000;
  z-index: 100;
  padding: 0;
  border: 1px solid #555;
  border-top: none;
  color: #000;
`;

const SearchResultOption = styled.a<{ $backdropUrl?: string | undefined }>`
  ${({ $backdropUrl }) =>
    $backdropUrl
      ? `background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.9), rgba(50, 50, 50, 0.8)), url(${$backdropUrl});`
      : ""}
  background-size: cover;
  background-position: center;
  border-top: 1px solid #555;
  text-decoration: none;
  color: #ffffff;
  display: block;

  &:hover {
    text-decoration: none;
    ${({ $backdropUrl }) =>
      $backdropUrl
        ? `background-image: linear-gradient(90deg, rgba(40, 40, 40, 0.9), rgba(90, 90, 90, 0.8)), url(${$backdropUrl});`
        : "#222222"}
   }

  & h3 small, h4 small {
      margin-left: 4px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  & h3 {
    font-size: 16px;
    line-height: 18px;
  }

  & h4 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: -2px;
  }
`;

const SearchResultOptionContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  align-items: center;
`;

const SearchResultPosterWrapper = styled.div`
  height: 100%;

  & img {
    height: 100%;
    width: auto;
  }
`;

function SearchResultPoster({ posterPath }: { posterPath: string }) {
  return posterPath ? (
    <SearchResultPosterWrapper>
      <img
        alt="Search result poster"
        src={`https://image.tmdb.org/t/p/w92/${posterPath}`}
      />
    </SearchResultPosterWrapper>
  ) : null;
}

function MovieSearchResult({ option }: { option: Movie }) {
  return (
    <SearchResultOption
      href={`/movie/${option.id}`}
      $backdropUrl={`https://image.tmdb.org/t/p/w300/${option.paths.backdrop}`}
    >
      <SearchResultOptionContainer>
        <div>
          <h4>Movie</h4>
          <h3>
            {option.title}
            {option.releaseDate && (
              <small>({option.releaseDate.getFullYear()})</small>
            )}
          </h3>
        </div>
        <SearchResultPoster posterPath={option.paths.poster} />
      </SearchResultOptionContainer>
    </SearchResultOption>
  );
}

function TVSearchResult({ option }: { option: TVShow }) {
  return (
    <SearchResultOption
      href={`/tv/${option.id}`}
      $backdropUrl={`https://image.tmdb.org/t/p/w300/${option.paths.backdrop}`}
    >
      <SearchResultOptionContainer>
        <div>
          <h4>TV Show</h4>
          <h3>
            {option.name}
            {option.firstAirDate && (
              <small>({option.firstAirDate.getFullYear()})</small>
            )}
          </h3>
        </div>
        <SearchResultPoster posterPath={option.paths.poster} />
      </SearchResultOptionContainer>
    </SearchResultOption>
  );
}

function PersonSearchResult({ option }: { option: Person }) {
  return (
    <SearchResultOption href={`/person/${option.id}`}>
      <SearchResultOptionContainer>
        <div>
          <h3>{option.name}</h3>
        </div>
        <SearchResultPoster posterPath={option.profilePath} />
      </SearchResultOptionContainer>
    </SearchResultOption>
  );
}

function SearchInput() {
  const [query, setQuery] = React.useState("");
  const [options, setOptions] = React.useState<SearchResult[]>([]);

  const searchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const search = async () => {
      if (!query) {
        setOptions([]);
        return;
      }

      const results = await api.search.get(query);

      if (!results) {
        return;
      }
      setOptions(results);
    };

    const timeout = window.setTimeout(search, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  const handleSearch = async ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value.trim());
  };

  return (
    <div>
      <SearchField
        ref={searchRef}
        label="Search movies, TV shows or celebs"
        size="small"
        sx={{ width: 500 }}
        inputProps={{
          autoComplete: "new-password", // disable autocomplete and autofill
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      {options.length > 0 && (
        <SearchResults>
          {options &&
            options.map((option: SearchResult) => (
              <div key={option.id}>
                {option.mediaType === MediaType.MOVIE ? (
                  <MovieSearchResult option={option} />
                ) : option.mediaType === MediaType.TV_SHOW ? (
                  <TVSearchResult option={option} />
                ) : (
                  <PersonSearchResult option={option} />
                )}
              </div>
            ))}
        </SearchResults>
      )}
    </div>
  );
}

export default SearchInput;
