import movies from './movies';
import search from './search';

export const MOVIE_DB_API_KEY = process.env.REACT_APP_MOVIE_DB_API_KEY;
export const MOVIE_DB_API_URL = 'https://api.themoviedb.org/3';

const api = {
  movies,
  search,
};

export default api;
