import React from "react";
import styled from "styled-components";
import { Container } from "@mui/material";
import { MovieFull } from "../../api/types";
import { getColorsForRating, getOpinion } from "../../utils/ratings";
import WokeMeter from "../../components/WokeMeter";
import { PlayArrow } from "@mui/icons-material";
import MovieVideoModal from "../../components/Movies/MovieModalVideos";

const Hero = styled.div<{ $backgroundSrc: string }>`
  background: linear-gradient(130deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)),
    url(${({ $backgroundSrc }) => $backgroundSrc}) no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #ffffff;
  font-size: 19px;
  width: 100%;
  min-height: 400px;
  max-height: 600px;
  padding: 24px 0;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 32px;
`;

const Rating = styled.span`
  font-family: sans-serif;
  font-size: 20px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 2px 8px;
  margin-left: 16px;
  position: relative;
  bottom: 11px;
`;

const RatingsArea = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
`;

const Overview = styled.div`
  margin-top: 24px;
  font-size: 16px;
`;

const Title = styled.h1`
  font-family: "Bangers";
  display: block;
  color: #ffffff;
  font-size: 50px;
  line-height: 60px;
  height: 60px;
  font-weight: normal;
  white-space: nowrap;
`;

const Tagline = styled.div`
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 24px;
  font-style: italic;
`;

const PosterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & div {
    border-radius: 5px;
    filter: drop-shadow(2px 2px 7px #000000);
    background: #ffffff;
    overflow: hidden;
  }
`;

const Poster = styled.img`
  display: block;
  max-height: 340px;
`;

const TrailerLink = styled.span`
  background: #ffffff;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  padding: 8px 0;
  cursor: pointer;
  filter: drop-shadow(2px 2px 7px #000000);
`;

const RatingWithBorder = styled.b`
  border-radius: 10px;
  padding: 2px 8px;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 4px;

  & b {
    font-size: 30px;
  }
`;

const RatingCircle = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #555;
  opacity: 0.6;
  margin-left: 8px;
`;

const ZoneOpinion = styled.div`
  font-size: 20px;
  background: rgba(0, 0, 0, 1);
  border-radius: 10px;
  margin-top: 16px;
  border: 1px solid #ffffff;
  padding: 12px 32px;
  display: inline-block;
`;

const BangersFont = styled.span`
  font-family: "Bangers";
  font-weight: normal;
  font-size: 25px;
  white-space: nowrap;
`;

type MovieHeroProps = {
  movie: MovieFull;
};

const MovieHero: React.FC<MovieHeroProps> = ({ movie }) => {
  const [trailerModalOpen, setTrailerModalOpen] = React.useState(false);

  const { description: wokeDescription } = getOpinion(
    movie.wokeLevel,
    movie.votes.average
  );

  return (
    <Hero
      $backgroundSrc={`https://image.tmdb.org/t/p/w1280/${movie.paths.backdrop}`}
    >
      <Container maxWidth="xl">
        <Row>
          <PosterContainer>
            <div>
              <Poster
                alt="Movie poster"
                src={`https://image.tmdb.org/t/p/w500/${movie.paths.poster}`}
              />
              <TrailerLink onClick={() => setTrailerModalOpen(true)}>
                View Trailer <PlayArrow />
              </TrailerLink>
              <MovieVideoModal
                open={trailerModalOpen}
                onClose={() => setTrailerModalOpen(false)}
              />
            </div>
          </PosterContainer>
          <div>
            <Title>
              {movie.title}
              {movie.releaseCertification && (
                <Rating>{movie.releaseCertification}</Rating>
              )}
            </Title>
            {movie.tagline && <Tagline>{movie.tagline}</Tagline>}
            <div>{movie.genres.map(({ name }) => name).join(", ")}</div>
            {movie.releaseDate && (
              <div>
                {movie.releaseDate > new Date()
                  ? "Planned release in"
                  : "Released"}{" "}
                <b>{movie.releaseDate.getFullYear()}</b>
                {movie.runtime && (
                  <span>
                    {" "}
                    | Runtime{" "}
                    <b>
                      {movie.runtime.hours && `${movie.runtime.hours}h `}
                      {movie.runtime.minutes}m
                    </b>
                  </span>
                )}
              </div>
            )}
            <Overview>{movie.overview}</Overview>
          </div>
          <div style={{ textAlign: "center" }}>
            <WokeMeter wokeLevel={movie.wokeLevel} />
            <div style={{ marginTop: 16 }}>
              <div>Zone Opinion</div>
              <BangersFont>{wokeDescription}</BangersFont>
            </div>
            <ZoneOpinion>
              <RatingsArea>
                <RatingWithBorder>
                  Woke Level
                  <br />
                  <b>{movie.wokeLevel != null ? movie.wokeLevel : "-"}</b>
                  {movie.wokeLevel != null && (
                    <RatingCircle
                      style={{
                        backgroundColor: getColorsForRating(
                          10 - movie.wokeLevel
                        )[0],
                      }}
                    />
                  )}
                </RatingWithBorder>
                <RatingWithBorder>
                  User rating
                  <br />
                  <b>{Math.round(movie.votes.average * 10)}%</b>
                  <RatingCircle
                    style={{
                      backgroundColor: getColorsForRating(
                        movie.votes.average
                      )[0],
                    }}
                  />
                </RatingWithBorder>
              </RatingsArea>
            </ZoneOpinion>
          </div>
        </Row>
      </Container>
    </Hero>
  );
};

export default MovieHero;
