import { Container, Grid } from "@mui/material";
import styled from "styled-components";
import { LogoVertical } from "../components/Logo";
import instagramIcon from "../images/social-icons/instagram.png";
import facebookIcon from "../images/social-icons/facebook.png";
import twitterIcon from "../images/social-icons/twitter.png";
import youtubeIcon from "../images/social-icons/youtube.png";

const FooterContainer = styled.div`
  margin-top: 64px;
  background: #191919;
  color: #ffffff;
`;

const FooterDiv = styled(Grid)`
  height: 200px;
`;

const LinkList = styled.ul``;

const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;

  & li img {
    max-width: 48px;
  }
`;

const BottomLegal = styled.div`
  padding: 8px 0;
  text-align: center;
  font-size: 14px;
`;

function Footer() {
  const date = new Date();

  return (
    <FooterContainer>
      <Container maxWidth="xl">
        <FooterDiv
          container
          justifyContent="space-between"
          columnGap="24px"
          alignItems="center"
        >
          <Grid item>
            <LogoVertical />
          </Grid>
          <Grid item>
            <LinkList>
              <li>
                <a href="#test">Popular movies</a>
              </li>
              <li>
                <a href="#test">Top rated movies</a>
              </li>
              <li>
                <a href="#test">Movie search</a>
              </li>
            </LinkList>
          </Grid>
          <Grid item>
            <LinkList>
              <li>
                <a href="#test">About the site</a>
              </li>
              <li>
                <a href="#test">How does the woke score work?</a>
              </li>
              <li>
                <a href="#test">Advertise and support</a>
              </li>
              <li>
                <a href="#test">Help &amp; contact us</a>
              </li>
            </LinkList>
          </Grid>
          <Grid item>
            <h3 style={{ textAlign: "center", marginBottom: "8px" }}>
              Follow us
            </h3>
            <SocialList>
              <li>
                <img alt="Instagram profile" src={instagramIcon} />
              </li>
              <li>
                <img alt="Twitter profile" src={twitterIcon} />
              </li>
              <li>
                <img alt="Facebook profile" src={facebookIcon} />
              </li>
              <li>
                <img alt="Youtube profile" src={youtubeIcon} />
              </li>
            </SocialList>
          </Grid>
        </FooterDiv>
        <BottomLegal>
          <span>
            Copyright &copy;{date.getFullYear()} No Woke Zone. All rights
            reserved.{" "}
          </span>
          <a href="privacy-policy">Privacy Policy</a> |{" "}
          <a href="terms-of-service">Terms of Service</a>
        </BottomLegal>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
