import React from "react";
import MoviesSectionPopular from "../../components/Movies/MoviesSectionPopular";
import MoviesSectionWokeAndNot from "../../components/Movies/MoviesSectionWokeAndNot";
import HomeHero from "./HomeHero";

function HomePage() {
  React.useEffect(() => {
    document.title = `No Woke Zone | Movie Woke Meter | Search for movies without agendas`;
  }, []);

  return (
    <>
      {/* <HomeHero /> */}
      <React.Suspense fallback={<span>Loading...</span>}>
        <MoviesSectionPopular />
      </React.Suspense>

      <React.Suspense fallback={<span>Loading...</span>}>
        <MoviesSectionWokeAndNot />
      </React.Suspense>
    </>
  );
}

export default HomePage;
