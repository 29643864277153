import axios from "axios";
import { MOVIE_DB_API_KEY, MOVIE_DB_API_URL } from "./index";
import { moviesFromApi, movieFullFromApi } from "./transforms";
import { APIMoviesResponse, APIMovieFull } from "./types";

const get = async (movieId: string) => {
  const { data } = await axios.get<APIMovieFull>(
    `${MOVIE_DB_API_URL}/movie/${movieId}`,
    {
      params: {
        api_key: MOVIE_DB_API_KEY,
        append_to_response: "credits,release_dates,videos",
      },
    }
  );

  return movieFullFromApi(data);
};

const getPopular = async () => {
  const { data } = await axios.get<APIMoviesResponse>(
    `${MOVIE_DB_API_URL}/movie/popular`,
    {
      params: {
        api_key: MOVIE_DB_API_KEY,
      },
    }
  );

  return moviesFromApi(data);
};

const api = {
  get,
  getPopular,
};

export default api;
