import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import VideoPlayer from "../VideoPlayer";
import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";
import { useAtom } from "jotai";
import { movieAtom } from "../../state/movie";
import { Video } from "../../api/types";

const MovieModal = styled(Modal)``;

const ModalBox = styled(Box)`
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1000px;
  height: 500px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.h3`
  font-size: 24px;
  background: #f7b139;
  padding: 8px 24px;
`;

const ModalColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 0;
  height: 100%;
`;

const VideoList = styled(Box)`
  max-width: 250px;
  padding: 16px 24px;
  overflow: hidden;
  overflow-y: auto;
`;

const MovieBox = styled(Box)`
  flex: 1;
`;

const Player = styled(VideoPlayer)``;

type MovieVideoModalType = {
  open: boolean;
  onClose: () => void;
};

const MovieVideoModal: React.FC<MovieVideoModalType> = ({ open, onClose }) => {
  const [movie] = useAtom(movieAtom);

  const { videos } = movie;

  const [videoKey, setVideoKey] = React.useState(videos[0].key);

  const videosByType: Record<string, Video[]> = {};

  videos.forEach((video) => {
    videosByType[video.type] = (videosByType[video.type] || []).concat(video);
  });

  const videoTypeSortArray = [
    "Trailer",
    "Teaser",
    "Clip",
    "Behind the Scenes",
    "Featurette",
    "Blooper",
  ];
  const videoTypes = Object.keys(videosByType);

  videoTypes.sort((a, b) => {
    const sortIndexA = videoTypeSortArray.indexOf(a);
    const sortIndexB = videoTypeSortArray.indexOf(b);

    return sortIndexA - sortIndexB;
  });

  const videoSrc = `https://youtube.com/watch?v=${videoKey}`;

  if (!videoSrc) {
    return null;
  }

  return (
    <MovieModal open={open} onClose={onClose}>
      <ModalBox>
        <ModalColumn>
          <ModalTitle>{movie.title}</ModalTitle>
          <ModalRow>
            <VideoList>
              {videoTypes.map((videoType) => (
                <React.Fragment key={videoType}>
                  <h4>{videoType}</h4>
                  <Tabs
                    orientation="vertical"
                    value={videoKey}
                    onChange={(_, newVideoKey) => setVideoKey(newVideoKey)}
                  >
                    {videosByType[videoType].map((video) => (
                      <Tab
                        key={video.key}
                        value={video.key}
                        label={video.name}
                      />
                    ))}
                  </Tabs>
                </React.Fragment>
              ))}
            </VideoList>
            <MovieBox>
              <Player key={videoKey} src={videoSrc} videoType="video/youtube" />
            </MovieBox>
          </ModalRow>
        </ModalColumn>
      </ModalBox>
    </MovieModal>
  );
};

export default MovieVideoModal;
